import {Mask, Toast} from "antd-mobile";
import React,{useEffect, useState} from "react";
import sdk from "@/service/sdk";
import {VideoInfo} from '@/types/data';
import Img from "@/components/Img";
import icon_hot from '@/assets/images/icon_hot.png';
import RechargeModal from "@/components/RechargeModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "@/types/store";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconBack from "@/components/icons/IconBack";
import logo from "@/assets/images/logo.png";
type Props = {
    show:boolean,
    info:VideoInfo,
    close: () => void,
}
const BuyModal = ({show,info,close}:Props) => {
    const [goodsList, SetGoodsList] = useState([])
    const [goodsItem, SetGoodsItem] = useState<any>({})
    const [channelList, SetChannelList] = useState([])
    const navigate = useNavigate()
    const [config] = useSelector(
        (state: RootState) => {
            return [state.app.config]
        }
    )
    const userInfo = useSelector(
        (state: RootState) => {
            return state.app.userInfo
        }
    )
    useEffect(() => {
        getList()
    }, [])
    const getList=()=>{
        sdk.getGoodsList().then((res) => {
            if(res.status==1)
            {
                SetGoodsList(res.data.item_list)
                SetGoodsItem(res.data.item_list[0])
                SetChannelList(res.data.channel_list)
            }

        })
    }
    return <Mask visible={show}>
        <div className="wrapper">
            <div className="block">
                <div className="van-image face">
                        <Img key={info.id+'cover'} src={info.cover_url}></Img>
                        <img className="water-mark" src={logo} />
                </div>
                <div className="title">{info.title}</div>
                <div className="item-list">
                    <div onClick={() => {
                        /*close()*/
                        RechargeModal(channelList,2,info)
                        SetGoodsItem(info)
                    }} className={goodsItem.id==info.id?"vip-box video-box item-active":"vip-box video-box"}>
                        <div className="box-left">
                            单片特价
                        </div>
                        <div className="box-right">
                            ￥{info.pay_amount_yuan}
                        </div>
                    </div>
                    {
                        goodsList.map((item:any,index)=>{
                            return(
                                <div key={item.id} onClick={() => {
                                   /* close()*/
                                    RechargeModal(channelList,1,item)
                                    SetGoodsItem(item)
                                }} className={goodsItem.id==item.id?'sc-1xbpqlx-0 dYLMTo item-active':'sc-1xbpqlx-0 dYLMTo'}>
                                    <div className="sc-1xbpqlx-4 bZSGlp"><span className="sc-1xbpqlx-2 lcOZYP">{item.title}</span>
                                        <div className="sc-1xbpqlx-1 YxulC"><p>{item.description}</p></div>
                                    </div>
                                    <div className="sc-1lypyuq-0 bvDcOs price-box">
                                        <span className="sc-1lypyuq-3 emiDzr">￥{item.price_yuan}</span>
                                        {
                                            index == 0 ? <img className="icon-hot" src={icon_hot}/>:<></>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        config.invitation_enable?<div id="copybtn" className="vip-box copy-box">
                            <CopyToClipboard text={config.share_url+'?'+userInfo.share_param} onCopy={() => Toast.show({
                                icon: 'success',
                                content: '复制成功!',
                            })}>
                                <div className="box-left">
                                    {config.invitation_tip}
                                </div>
                            </CopyToClipboard>
                        </div>:<></>
                    }
                </div>
                <div onClick={()=>{
                    navigate(-1)
                }} className="pop-button">
                    <div className="more">返回</div>
                </div>
                <p className="office-site">官方网址:{config.site_url}</p>
                <div onClick={()=>{
                    close()
                    navigate(-1)
                }} className="pop-button pop-button-close">
                    <div className="close-button">
                        {/*<img src={closeIcon}/>*/}
                         <IconBack size={'22'}></IconBack>
                    </div>
                </div>
            </div>
        </div>
    </Mask>
}

export default BuyModal
